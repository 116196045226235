import React, { useState } from 'react'
import Authorized from '@components/authz/authorized'
import useTranslation from 'next-translate/useTranslation'
import setLanguage from 'next-translate/setLanguage'
import LangIcon from 'public/img/lang.svg'
import ProfileMenu from '../header/profile-menu'
import linksProps from './links-props'
import ProfileMenuButton from './profile-menu-button'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import Link from 'next/link'
import { useSelector } from 'noval'
import { buildClass } from '@util/tag'

export const MenuToolBar = ({ callback }) => {
    const { asPath } = useRouter()
    const { t, lang } = useTranslation()
    const user = useSelector('userInfo')
    const isProfile = asPath.match('/profile')
    const localSwitchTarget = lang === 'ar' ? 'en' : 'ar'
    const { toggleMenu } = useSelector('toggleMenu', false)

    return (
        <>
            <div className="flex flex-col items-center relative gap-3 h-full w-full overflow-auto">
                {linksProps(user?.notification, false).map(
                    ({
                        role,
                        labelKey,
                        href,
                        icon,
                        customIconStyle,
                        fill,
                        callbackFunction,
                        isNotification,
                        isWarning
                    }) => (
                        <Authorized role={role} key={href}>
                            <Tool
                                callback={
                                    callbackFunction !== 'none' && callback
                                }
                                toggle={toggleMenu}
                                labelKey={labelKey}
                                href={href}
                                icon={icon}
                                customIconStyle={customIconStyle}
                                fill={fill}
                                isNotification={isNotification}
                                isWarning={isWarning}
                            />
                        </Authorized>
                    )
                )}
            </div>

            <div className="flex w-full flex-col justify-between gap-1 mt-5 relative">
                <ProfileMenu
                    userName={user?.userInfo?.fullName}
                    profilePicture={user?.userInfo?.personalPicture}
                    renderButton={
                        <ProfileMenuButton
                            userName={user?.userInfo?.fullName}
                            isProfile={isProfile}
                            toggleMenu={toggleMenu}
                            profilePicture={user?.userInfo?.personalPicture}
                        />
                    }
                />

                <div className="flex w-full px-6 relative mb-2">
                    <div className="flex w-full h-[1px] relative bg-light-400" />
                </div>
                <div
                    className="flex w-full flex-row justify-center items-center px-6 gap-2 cursor-pointer"
                    onClick={() => setLanguage(localSwitchTarget)}
                >
                    <h3 className="capitalize text-md text-dark-200">
                        {t(`common:header.language.${localSwitchTarget}`)}
                    </h3>
                    {toggleMenu ? null : (
                        <LangIcon className="text-dark-200 relative cursor-pointer" />
                    )}
                </div>
            </div>
        </>
    )
}

const Tool = ({
    labelKey,
    icon,
    customIconStyle,
    fill = false,
    href,
    toggle,
    callback,
    isNotification = false,
    isWarning = false
}) => {
    const { asPath } = useRouter()
    const isActive = asPath === href
    const [mouseEnter, setMouseEnter] = useState(false)
    return (
        <Link href={href}>
            <a
                onClick={() => callback && callback()}
                className={buildClass(
                    'flex flex-row w-full h-[38px] min-h-[38px] 2xl:h-[56px] 2xl:min-h-[56px]',
                    'justify-start items-center transition duration-200',
                    'ltr:border-l-4 rtl:border-r-4 border-secondary-100 gap-3',
                    'hover:text-primary-600 hover:border-current focus:outline-none',
                    isActive
                        ? 'text-primary-600 border-current'
                        : 'text-dark-200'
                )}
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
            >
                <div
                    className={buildClass(
                        'flex justify-center items-center',
                        toggle ? 'lg:ms-11 ms-8' : 'ms-8 '
                    )}
                >
                    {React.cloneElement(icon, {
                        className: buildClass(
                            'transform scale-80 2xl:scale-100',
                            fill ? 'fill-current' : 'stroke-current',
                            (mouseEnter || isActive) &&
                            (fill ? 'fill-[#801E41]' : 'stroke-[#801E41]'),
                            { 'scale-100 2xl:scale-125': toggle },
                            customIconStyle
                        )
                    })}
                </div>
                {toggle ? null : (
                    <span className="2xl:text-md text-[14px] whitespace-nowrap capitalize text-center">
                        <Trans i18nKey={labelKey} />
                    </span>
                )}
                {isNotification && (
                    <span className="bg-[#801E41] rounded-full h-4 w-4 ms-auto me-5" />
                )}
                {isWarning && (
                    <span className="bg-[red] rounded-sm h-4 w-4  " />
                )}
            </a>
        </Link>
    )
}
