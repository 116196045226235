import { hasRole } from '@util/roles'
import { useSelector } from 'noval'

const Authorized = ({ role, children }) => {
    const roles = useSelector('roles')
    const email = useSelector('userInfo.userInfo.email', "")

    if (role && hasRole(role, roles) || role === email) {
        return children
    } else {
        return null
    }
}

export default Authorized
