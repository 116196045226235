import { useRouter } from 'next/router'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'noval'
import Toolbar from '@components/toolbar/toolbar'
import ToolbarPhone from './toolbar/toolbar-phone'
import useTranslation from 'next-translate/useTranslation'

const Layout = ({ children }) => {
    const { asPath } = useRouter()
    const { lang } = useTranslation()
    const wideScreen = isWideScreen(asPath)
    const { dispatch, addState } = useDispatch()
    const [userInfo, screen] = useSelector(['userInfo', 'screen'])
    const isPhoneScreen = screen === 'phone'
    const isTabletScreen = screen === 'tablet'
    const isWideTabletScreen = wideScreen || isTabletScreen

    useEffect(() => {
        const isTutorialNeeded = userInfo?.isTutorialNeeded
        const defaultMenu = userInfo?.userFavorite?.navBarMode
        const toggleMenu = isWideTabletScreen ? true : defaultMenu

        if (!isPhoneScreen) {
            addState({ isTutorialNeeded })
            dispatch({ defaultMenu, toggleMenu }, 'toggleMenu')
        } else dispatch({ isTutorialNeeded })
    }, [userInfo, isWideTabletScreen, isPhoneScreen])

    useEffect(() => {
        const observerResize = () => {
            switch (true) {
                case window.innerWidth > 1279:
                    dispatch({ screen: 'desktop' })
                    break
                case window.innerWidth <= 1279 && window.innerWidth > 1024:
                    dispatch({ screen: 'laptop' })
                    break
                case window.innerWidth <= 1024 && window.innerWidth > 640:
                    dispatch({ screen: 'tablet' })
                    break

                default:
                    dispatch({ screen: 'phone' })
                    break
            }
        }

        observerResize()
        window.addEventListener('resize', observerResize)
        return () => {
            window.removeEventListener('resize', observerResize)
        }
    }, [])

    return isFullPage(asPath) ? (
        children
    ) : (
        <div className="h-screen flex md:flex-row flex-col">
            <div
                className="h-screen z-[100] bg-white hidden md:flex"
                style={{
                    boxShadow: `${
                        lang === 'ar' ? '-30px' : '30px'
                    }  0 50px 0 rgba(30, 32, 35, 0.05)`
                }}
            >
                <Toolbar forceClose={wideScreen} />
            </div>
            <div className="w-screen min-h-[80px] h-20 z-[100] bg-body flex md:hidden">
                <ToolbarPhone />
            </div>
            <CreateBody wideScreen={wideScreen}>{children}</CreateBody>
        </div>
    )
}

const CreateBody = ({ wideScreen, children }) => {
    const notifications = useSelector('notifications', [])
    return (
        <div className="flex flex-col flex-grow justify-between overflow-y-auto bg-body">
            {notifications.length !== 0 && (
                <div className="absolute inset-0 w-full h-full bg-[#071512] opacity-50 z-[500]"></div>
            )}
            {wideScreen ? (
                children
            ) : (
                <div className="flex-grow flex flex-col lg:pt-14 pt-10 sm:pt-0">
                    {/* NOTE padding X effect on bottom bar in /orders/template */}
                    {children}
                </div>
            )}
        </div>
    )
}

const isWideScreen = (url) => {
    return [
        /^(\/(ar|en))?\/(survey)\//,
        /^(\/(ar|en))?\/(supervisor\/team-management)\/(\d+)/,
        /^(\/(ar|en))?\/(.*?\/orders)\/(\d+)/,
        /^(\/(ar|en))?\/(.*?\/on-track)\/(\d+)/,
        /^(\/(ar|en))?\/(.*?\/assigned-orders)\/([^/]+)/,
        /^(\/(ar|en))?\/(studies\/review)\/(\d+)/,
        /^(\/(ar|en))?\/(scale-builder)\//,
        /^(\/(ar|en))?\/(orders)\/(.*?)\/(addons|create-survey|design|launch|quote)/,
        /^(\/(ar|en))?\/(studies)\/(.*?)\/(create-survey|design)/
    ].some((regex) => regex.test(url))
}

const isFullPage = (url) => {
    return [
        /^(\/(ar|en))?.*?\/registration/,
        /^(\/(ar|en))?\/500/,
        /^(\/(ar|en))?\/404/,
        /^(\/(ar|en))?\/signup/,
        /^(\/(ar|en))?\/survey/,
        /^(\/(ar|en))?\/call-center/,
        /^(\/(ar|en))?\/scale\/.*/,
        /^(\/(ar|en))?\/forget-password/,
        /^(\/(ar|en))?\/become-a-customer/,
        /^(\/(ar|en))?\/request-service/,
        /^(\/(ar|en))?\/(anonymous\/review)\/(\d+)/,
        /^(\/(ar|en))?\/(supervisor\/responses)\/(\d+)/,
        /^\/targetAudience\/.*/
    ].some((fullPageRegex) => fullPageRegex.test(url))
}

export default memo(Layout)
