import React from 'react'
import ArrowLeft from 'public/img/arrows/right-arrow.svg'
import { useSelector } from 'noval'
import { buildClass } from '@util/tag'

const ProfileMenuButton = ({ isProfile, userName, profilePicture }) => {
    const { toggleMenu } = useSelector('toggleMenu', true)
    return (
        <div
            className={buildClass(
                'flex w-full flex-row items-center justify-between',
                'relative ltr:border-l-4 gap-2 py-2 px-6',
                { 'rtl:border-r-4 border-primary-600': isProfile }
            )}
        >
            <div className="flex w-full flex-row items-center justify-start gap-2">
                <div className="flex overflow-hidden">
                    <img
                        className="2xl:w-16 w-12"
                        src={profilePicture ?? '/img/users/avatar.jpg'}
                    />
                </div>
                {toggleMenu ? null : (
                    <div className="flex flex-col justify-center items-start gap-1">
                        <h3 className="capitalize text-md text-dark-400">
                            {userName}
                        </h3>
                    </div>
                )}
            </div>
            {toggleMenu ? null : (
                <ArrowLeft
                    className={buildClass(
                        'w-6 h-6 text-dark-100 relative ltr:right-[-6px] rtl:rotate-180',
                        'rtl:left-[-6px] cursor-pointer transform fill-current'
                    )}
                />
            )}
        </div>
    )
}

export default ProfileMenuButton
