import Trans from 'next-translate/Trans'

export const handleApiError = (error, dispatch) => {
    const response = error?.response
    const { errorCode = 'default' } = response?.data

    const info = (
        <Trans i18nKey={`common:notifications.serverError.${errorCode}`} />
    )

    if (dispatch) {
        dispatch('notify', {
            type: 'error',
            title: errorCode,
            info
        })
    } else return info
}

export const handleDgsError = (code, dispatch) => {
    const info = <Trans i18nKey={`common:notifications.serverError.${code}`} />

    if (dispatch) {
        dispatch('notify', {
            type: 'error',
            title: code,
            info
        })
    } else return info
}
