import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import EditIcon from 'public/img/users/user-edit.svg'
import LogoutIcon from 'public/img/profile/logout.svg'
import UsersIcon from 'public/img/users/users-grey.svg'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { useDispatch } from 'noval'
import { useRouter } from 'next/router'
import { useSWRConfig } from 'swr'
import Authorized from '@components/authz/authorized'
import { ADMIN } from '@util/roles'

const ProfileMenu = ({ renderButton, userName, profilePicture }) => {
    const { push } = useRouter()
    const { t } = useTranslation()
    const { cache } = useSWRConfig()
    const { dispatch } = useDispatch()

    const onLogout = () =>
        dispatch('onLogout', {
            callback: () => {
                push('/')
                cache.clear()
            }
        })

    return (
        <Menu
            transition
            arrow={true}
            direction="left"
            className="z-10"
            menuClassName="filter !drop-shadow-lg !mt-[-25px]"
            menuButton={
                <MenuButton className="focus:outline-none">
                    {renderButton}
                </MenuButton>
            }
        >
            <div className="flex flex-col overflow-hidden p-3 gap-2 focus:outline-none">
                <MenuItem
                    onClick={null}
                    className={`!px-2 !py-2 min-w-[198px] border-b-[1px] border-light-400 capitalize hover:bg-secondary-100`}
                >
                    <div className="flex w-full flex-row items-center justify-start gap-2">
                        <div className="flex overflow-hidden">
                            <img
                                className="2xl:w-14 w-10"
                                src={profilePicture ?? '/img/users/avatar.jpg'}
                            />
                        </div>
                        <div className="flex flex-col justify-center items-start gap-1">
                            <h3 className="capitalize text-md text-dark-400">
                                {userName}
                            </h3>
                        </div>
                    </div>
                </MenuItem>
                
                <MenuItem
                    className={`!px-2 !py-2 min-w-[198px] text-md text-dark-200 hover:bg-light-100 hover:text-primary-600`}
                >
                    <Link href="/profile">
                        <a className="flex gap-2">
                            <EditIcon className="fill-current relative top-[3px]" />
                            {t('common:header.profile.profile')}
                        </a>
                    </Link>
                </MenuItem>
                
                <Authorized role={[ADMIN]}>
                    <MenuItem
                        className={`!px-2 !py-2 min-w-[198px] text-md text-dark-200 hover:bg-light-100 hover:text-primary-600`}
                    >
                        <Link href="/researcher/my-supervisor">
                            <a className="flex gap-2 items-center">
                                <UsersIcon className="fill-current relative top-[3px w-5" />
                                {t('common:header.mySupervisor.mySupervisorDetails')}
                            </a>
                        </Link>
                    </MenuItem>
                </Authorized>
                <MenuItem
                    onClick={onLogout}
                    className={`!px-2 !py-2 min-w-[198px] text-md text-dark-200 hover:bg-light-100 hover:text-danger-600`}
                >
                    <span className="flex gap-2">
                        <LogoutIcon className="fill-current relative top-[3px]" />
                        {t('common:header.profile.logout')}
                    </span>
                </MenuItem>
            </div>
        </Menu>
    )
}

export default ProfileMenu
