import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {
    authStorage,
    authnConfig,
    dataFromAuth,
    userStorage
} from '@util/noval-helper/helper'
import ClientOAuth2 from 'client-oauth2'
import http from '@hooks/http'
import { exportResponses, userData } from 'gql/user'

const generateId = () => {
    const crypto = window.crypto || window.msCrypto
    return crypto.getRandomValues(new Uint32Array(1))[0]
}

const createDispatch = (data, tools, actions) => {
    const { type, payload } = data
    const { state, update, addState, thunk } = tools

    const { authn, helper, notifications = [], responsesById } = state

    const keycloakInit = () => {
        const { locale } = payload
        if (helper?.keycloakClient) return
        const keycloak = new ClientOAuth2({
            clientId: authnConfig.clientId,
            accessTokenUri: authnConfig.accessTokenUri,
            authorizationUri: authnConfig.authorizationUri,
            redirectUri:
                locale === 'ar'
                    ? `${authnConfig.redirectUri}/dashboard`
                    : `${authnConfig.redirectUri}/en/dashboard`,
            scopes: ['openid'],
            query: {
                ui_locales: locale
            }
        })
        onLogin(keycloak)
        update({ keycloakClient: keycloak }, 'helper')
    }

    const getResponsesById = async () => {
        const { surveyId, onStartFetch, onEndFetch } = payload
        const responseBySurveyId = responsesById?.[surveyId]

        onStartFetch && onStartFetch()
        if (!responseBySurveyId) {
            http(
                'exportResponsesBySurveyId',
                {
                    vars: { surveyId },
                    body: exportResponses,
                    opts: {
                        callback: ({ exportResponsesBySurveyId }) => {
                            update({ [surveyId]: exportResponsesBySurveyId }, "responsesById")
                            onEndFetch && onEndFetch(exportResponsesBySurveyId)
                        }
                    }
                }
            )
        } else {
            onEndFetch && onEndFetch(responseBySurveyId)
        }
    }

    const refreshUser = async () => {
        await http('loggedUser', {
            body: userData,
            opts: {
                callback: (res) => {
                    const userInfo = res?.loggedUser || {}
                    userStorage.set(userInfo)
                    update({ userInfo })
                }
            }
        })
    }

    const activeToken = async () => {
        const { callback } = payload
        if (!authn) {
            return null
        } else if (Date.now() > jwtDecode(authn.access_token).exp * 1000) {
            try {
                const token = helper?.keycloakClient?.createToken(
                    authn.access_token,
                    authn.refresh_token
                )
                const newAuthn = await token.refresh()
                const authnData = await newAuthn.data
                authStorage.set(authnData)
                const dataParse = dataFromAuth(authnData)

                await http('loggedUser', {
                    body: userData,
                    opts: {
                        callback: (res) => {
                            userStorage.set(res?.loggedUser)
                            addState({ userInfo: res?.loggedUser })
                        }
                    }
                })
                update({ authn: authnData, ...dataParse })
                return authnData.access_token
            } catch (error) {
                update({
                    authn: null,
                    userInfo: null,
                    surveyList: [],
                    ...dataFromAuth(null)
                })
                authStorage.clear()
                userStorage.clear()
                callback && callback()
                return null
            }
        } else {
            return authn.access_token
        }
    }

    const onLogin = async (keycloak) => {
        if (keycloak && window.location.href.includes('?state')) {
            update({ initLogin: false }, 'helper')
            try {
                const callbackAuthn = await keycloak?.code?.getToken(
                    window.location.href
                )
                if (callbackAuthn?.data) {
                    authStorage.set(callbackAuthn.data)
                    const dataParse = dataFromAuth(callbackAuthn.data)
                    addState({ initLogin: true }, 'helper')
                    update({
                        authn: callbackAuthn.data,
                        ...dataParse
                    })
                    const res = await http('loggedUser', {
                        body: userData
                    })
                    const userInfo = res?.loggedUser || {}
                    userStorage.set(userInfo)
                    update({
                        userInfo,
                        authn: callbackAuthn.data,
                        ...dataParse
                    })
                } else {
                    throw new Error('Failed to retrieve token data')
                }
                window.location.href = `${authnConfig.redirectUri}/dashboard`
            } catch (error) {
                window.location.href = authnConfig.redirectUri
            }
        } else if (!authn) {
            const data = authStorage.get()
            const userInfo = userStorage.get()
            update({
                userInfo,
                authn: data,
                surveyList: [],
                ...dataFromAuth(data)
            })
        }
    }

    const onLogout = async () => {
        const { callback } = payload
        const body = new URLSearchParams()
        body.append('client_id', authnConfig.clientId)
        body.append('refresh_token', authn?.refresh_token)
        try {
            await axios.post(authnConfig.logoutUri, body, {
                headers: {
                    Authorization: `bearer ${authn?.access_token}`,
                    'content-type': 'application/x-www-form-urlencoded'
                }
            })
            addState({ initLogin: true }, 'helper')
            update({ authn: null, ...dataFromAuth(null) })
            authStorage.clear()
            userStorage.clear()
            callback && callback()
        } catch { }
    }

    function notify() {
        const virId = generateId()

        update({
            notifications: [
                ...notifications,
                {
                    virId,
                    ...payload
                }
            ]
        })

        setTimeout(
            () =>
                thunk(({ state: { notifications } }) => {
                    update({
                        notifications: notifications.filter(
                            (n) => n.virId !== virId
                        )
                    })
                }),
            3000
        )
    }

    switch (type) {
        case actions.getResponsesById:
            return getResponsesById()
        case actions.keycloakInit:
            return keycloakInit()
        case actions.refreshUser:
            return refreshUser()
        case actions.activeToken:
            return activeToken()
        case actions.onLogout:
            return onLogout()
        case actions.notify:
            return notify()
        default:
            break
    }
}

export default createDispatch
