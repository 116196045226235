import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import MainLogo from 'public/img/logos/upsilon-logo.svg'
import useTranslation from 'next-translate/useTranslation'
import NavBarPhone from './navbar-phone'
import { buildClass } from '@util/tag'
import setLanguage from 'next-translate/setLanguage'
import PrimaryButton from '@components/shared/primary-buttons/primary-button'

export const links = (lang, setLanguage, localSwitchTarget) => [
    { href: `${lang}/`, name: 'admin.home' },
    {
        href: `${lang}/dashboard`,
        name: window.localStorage.authn ? 'toolbar.dashboard' : 'home.login'
    },
    {
        href: '',
        onClick: () => setLanguage(localSwitchTarget),
        name: `header.language.${localSwitchTarget}`
    }
]

const Nav = () => {
    const [isPaneOpen, setIsPaneOpen] = useState(false)
    const [stickNav, setStickNav] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            window.scrollY !== 0 ? setStickNav(true) : setStickNav(false)
        })
    }, [])

    return (
        <div className="relative w-full h-[90px] 2xl:h-[145px]">
            <nav
                className={buildClass(
                    'bg-white fixed w-full top-0 left-0 transition-all duration-500 z-[100] py-8 2xl:py-10',
                    'flex justify-center items-center px-[5rem] 2xl:px-[100px]',
                    stickNav && 'shadow-md'
                )}
            >
                <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-[30px] lg:gap-[72px] w-full">
                        <Link href="/dashboard">
                            <a className="cursor-pointer flex justify-center items-center">
                                <MainLogo className="w-[175px]" fill="#fff" />
                            </a>
                        </Link>
                        <RenderLinks />
                    </div>

                    <div className="md:hidden flex relative">
                        <button
                            onClick={() => setIsPaneOpen((old) => !old)}
                            className="w-14 h-14 bg-transparent"
                        >
                            <img src="/img/sort.png" alt="sort" />
                        </button>
                    </div>
                </div>
            </nav>

            <NavBarPhone
                isPaneOpen={isPaneOpen}
                setIsPaneOpen={setIsPaneOpen}
            />
        </div>
    )
}

const RenderLinks = () => {
    const router = useRouter()
    const { t, lang } = useTranslation()
    const localSwitchTarget = lang === 'ar' ? 'en' : 'ar'

    return (
        <ul className="hidden md:flex items-center w-full border border-light-600">
            {links(lang, setLanguage, localSwitchTarget).map(
                ({ href, onClick, name }, i) => {
                    return (
                        <li
                            key={i}
                            className="w-1/4 text-center border-r border-light-600 h-12 lg:h-16 flex items-center justify-center"
                        >
                            {' '}
                            {href !== '' ? (
                                <Link href={href}>
                                    <a className="capitalize text-[14px] lg:text-md hover:text-primary-600">
                                        {t(`common:${name}`)}
                                    </a>
                                </Link>
                            ) : (
                                <div
                                    onClick={onClick}
                                    className="capitalize text-[14px] lg:text-md cursor-pointer hover:text-primary-600"
                                >
                                    {' '}
                                    {t(`common:${name}`)}
                                </div>
                            )}
                        </li>
                    )
                }
            )}
            <PrimaryButton
                customClassName="w-1/4 h-12 lg:h-16 !px-1 text-[14px] lg:text-md"
                onClick={() => router.push('/become-a-customer')}
            >
                {t('common:home.becomeACustomer')}
            </PrimaryButton>
        </ul>
    )
}

export default Nav
