import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Typography } from '@mui/material'
import Phone from 'public/img/home/footer/call.svg'
import Linkedin from 'public/img/home/footer/linkedin.svg'
import Twitter from 'public/img/home/footer/twitter.svg'

const Footer = () => {
    const { t } = useTranslation()

    const icons = [
        {
            Icon: <Twitter className="w-6" />,
            alt: 'twitter',
            href: 'https://twitter.com/DemDevCo'
        },
        {
            Icon: <Linkedin />,
            alt: 'Linkedin',
            href: 'https://www.linkedin.com/company/demdevco/'
        },
        {
            Icon: <Phone className="transform scale-75" />,
            alt: 'call'
        }
    ]

    return (
        <div className="flex flex-col md:flex-row items-center md:justify-between gap-18 px-10 lg:px-30 border-t-2 border-black py-4 lg:py-10">
            <div className="text-md text-center">
                {t('common:footer.title', { year: new Date().getFullYear() })}
            </div>
            <div className="flex gap-16 md:gap-6 items-center">
                {icons.map(({ Icon, alt, href }) => {
                    return (
                        <div className="" key={alt}>
                            <Typography
                                component={'a'}
                                href={href}
                                target="_blank"
                            >
                                {Icon}
                            </Typography>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Footer
