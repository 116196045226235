function mergeStyle(variants, { controls, className = '' }) {
    let style = ''

    for (const keyName in controls) {
        const valueName = controls[keyName]
        const currnet = variants[keyName][valueName]
        if (currnet === undefined) continue
        style = `${style} ${currnet}`
    }

    return `${style} ${variants.default} ${className}`
}

export default mergeStyle
