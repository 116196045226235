import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'noval'

const Authentication = ({ children }) => {
    const { dispatch } = useDispatch()
    const { asPath, locale } = useRouter()

    const [authn, { initLogin, keycloakClient }] = useSelector([
        'authn',
        'helper'
    ])

    useEffect(() => {
        dispatch('keycloakInit', { locale })
    }, [locale])

    const isAuthn = Boolean(authn?.access_token || !isPrivate(asPath))
    const isNotAuthn = Boolean(!authn && isPrivate(asPath))

    switch (true) {
        case !keycloakClient?.code:
            return 'Loading ...'
        case isAuthn:
            return children
        case isNotAuthn && initLogin && typeof window !== 'undefined':
            window.location.href = keycloakClient.code.getUri()
            return null
        default:
            return null
    }
}

export const isPrivate = (url) => {
    const publicRoutes = [
        /^\/(\?.*)?$/,
        /^\/about/,
        /^\/terms-and-conditions/,
        /^\/services/,
        /^\/contact_us/,
        /^\/survey/,
        /^\/call-center/,
        /^\/scale/,
        /^\/signup/,
        /^\/forget-password/,
        /^\/become-a-customer$/,
        /^\/request-service$/,
        /^\/help/,
        /^\/404$/,
        /^\/500$/,
        /^\/(anonymous\/review)\/(\d+)/,
        /^\/images\/.*$/,
        /^\/targetAudience\/.*/,
        /^\/supervisor\/registration/,
        /^\/researcher\/registration/
    ]

    return !publicRoutes.some((path) => path.test(url))
}

export default Authentication
