import axios from 'axios'
import { useDispatch } from 'noval'
import { print, visit } from 'graphql'
import { useRouter } from 'next/router'
import useSWR, { useSWRConfig } from 'swr'
import { SURVEY_SERVICE_URL, getEnv } from '@util'

export const getNamedQuery = (cache, query) =>
    visit(query, {
        enter(node) {
            if (node.kind === 'OperationDefinition') {
                if (!node.name) {
                    return {
                        ...node,
                        name: { kind: 'Name', value: cache.join('_') }
                    }
                } else {
                    return false
                }
            } else {
                return node
            }
        }
    })

const useQuery = (
    key,
    query,
    variables,
    options = {
        swrOptions: {}
    },
    enabled = true
) => {
    const { push } = useRouter()
    const { cache } = useSWRConfig()
    const { dispatch } = useDispatch()

    const activeToken = () =>
        dispatch('activeToken', {
            callback: () => {
                push('/')
                cache.clear()
            }
        })

    const namedQuery = getNamedQuery(key, query)

    return useSWR(
        enabled ? key : null,
        async () => {
            const token = await activeToken()

            const headers = token
                ? {
                      Authorization: `Bearer ${token}`
                  }
                : {}

            return axios
                .post(
                    getEnv(SURVEY_SERVICE_URL),
                    {
                        query: print(namedQuery),
                        variables
                    },
                    { headers }
                )
                .then((response) => {
                    if (response.data.errors) {
                        const error = new Error(
                            'An error occurred while fetching the data.'
                        )
                        error.info = response.data.errors
                        throw error
                    } else {
                        options?.callback &&
                            options?.callback(response.data.data)
                        return response.data.data
                    }
                })
        },
        options?.swrOptions
    )
}

export default useQuery
