import Link from 'next/link'
import React, { useState } from 'react'
import MenuResize from 'public/img/action-menus/menu.svg'
import MainLogo from 'public/img/logos/upsilon-logo.svg'
import { MenuToolBar } from './menu-toolbar'
import { useDispatch } from 'noval'

const ToolbarPhone = () => {
    const { dispatch } = useDispatch()
    const [isPaneOpen, setIsPaneOpen] = useState(false)

    const onOverlayClick = (target) =>
        target.id === 'overlay' && setIsPaneOpen(false)

    return (
        <>
            <div className="flex w-full flex-row justify-between items-center gap-5 px-7 relative">
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setIsPaneOpen((old) => !old)
                        dispatch({
                            toggleMenu: true
                        })
                    }}
                >
                    <MenuResize
                        className="w-7 h-7"
                        style={{ fill: isPaneOpen ? '#801E41' : '#666666' }}
                    />
                </div>
                <DashboardLink icon={<MainLogo className="w-[50px]" />} />
            </div>
            <div
                id="overlay"
                className={`fixed ltr:right-0 rtl:left-0 w-full h-full transition-all duration-500 ${
                    isPaneOpen
                        ? 'ltr:right-0 rtl:left-0'
                        : 'ltr:right-[800px] rtl:left-[800px]'
                }`}
                onClick={({ target }) => onOverlayClick(target)}
            >
                <div className="h-full w-[270px] flex flex-col items-start py-3 bg-white relative shadow-md">
                    <div className="flex w-full flex-row justify-between gap-5 mb-14 lg:px-7 px-4 relative">
                        <DashboardLink
                            icon={<MainLogo className="w-[132px] mt-3" />}
                        />
                    </div>
                    <MenuToolBar callback={() => setIsPaneOpen(false)} />
                </div>
            </div>
        </>
    )
}

const DashboardLink = ({ icon }) => {
    return (
        <Link href="/dashboard">
            <a className="cursor-pointer">{icon}</a>
        </Link>
    )
}

export default ToolbarPhone
