import Link from 'next/link'
import { links } from './Nav'
import { buildClass } from '@util/tag'
import { useRouter } from 'next/router'
import MainLogo from 'public/img/logos/upsilon-logo-light.svg'
import CrossIcon from 'public/img/action-menus/close-white.svg'
import useTranslation from 'next-translate/useTranslation'
import NavbarButtons from './navbar-buttons'
import setLanguage from 'next-translate/setLanguage'

const NavBarPhone = ({ isPaneOpen, setIsPaneOpen }) => {
    const { asPath } = useRouter()
    const { t, lang } = useTranslation()
    const localSwitchTarget = lang === 'en' ? 'ar' : 'en'

    return (
        <div
            id="overlay"
            className={buildClass(
                'fixed top-0 ltr:right-0 rtl:left-0 w-full',
                'h-full transition-all duration-500',
                { 'ltr:right-0 rtl:left-0': isPaneOpen },
                { 'ltr:right-[-100%] rtl:left-[-100%]': !isPaneOpen }
            )}
            style={{ background: '#fffffff7', zIndex: '100' }}
            onClick={({ target: { id } }) =>
                id === 'overlay' && setIsPaneOpen(false)
            }
        >
            <div className="h-full w-full flex flex-col items-start p-5 relative gap-10">
                <div className="w-full flex items-start">
                    <div className="flex w-full items-center justify-between gap-5">
                        <Link href="/dashboard">
                            <a className="cursor-pointer flex justify-center items-center">
                                <MainLogo className="w-[132px]" />
                            </a>
                        </Link>
                        <button
                            onClick={() => setIsPaneOpen((old) => !old)}
                            className="w-14 h-14 bg-transparent"
                        >
                            <CrossIcon className="w-9 h-9 fill-current currentColor" />
                        </button>
                    </div>
                </div>
                <div className="h-full w-[300px] flex flex-col items-start gap-10">
                    {links(lang, setLanguage, localSwitchTarget).map(
                        ({ href, name }, i) => {
                            const active = asPath === href
                            return (
                                <div
                                    key={i}
                                    className={
                                        active
                                            ? 'text-primary-600'
                                            : 'text-dark-400'
                                    }
                                >
                                    <Link href={href}>
                                        <a className="text-base font-bold capitalize">
                                            {t(`common:${name}`)}
                                        </a>
                                    </Link>
                                </div>
                            )
                        }
                    )}

                    <NavbarButtons
                        localSwitchTarget={localSwitchTarget}
                        mainClass={'!py-3 w-[210px]'}
                    />
                </div>
            </div>
        </div>
    )
}

export default NavBarPhone
