import axios from 'axios'
import { print } from 'graphql'
import { useDispatch } from 'noval'
import { useRouter } from 'next/router'
import { gqlWithParams } from './useGql'
import Trans from 'next-translate/Trans'
import React, { useCallback } from 'react'
import { handleDgsError } from '@util/handle-errors'
import { getEnv, SURVEY_SERVICE_URL } from '@util'
import { mutate as swrMutate, useSWRConfig } from 'swr'

const useMutation = (options = defaultOptions) => {
    const { push } = useRouter()
    const { cache } = useSWRConfig()
    const { dispatch } = useDispatch()

    const activeToken = () =>
        dispatch('activeToken', {
            callback: () => {
                push('/')
                cache.clear()
            }
        })

    const mutate = useCallback(
        async (name, { vars, body, opts = {}, onError }, cache = []) => {
            const token = await activeToken()
            const { query, variables } = gqlWithParams(
                { name, vars, body },
                'mutation'
            )

            const headers = token
                ? {
                      Authorization: `Bearer ${token}`
                  }
                : {}

            return axios
                .post(
                    getEnv(SURVEY_SERVICE_URL),
                    {
                        variables,
                        query: print(query)
                    },
                    { headers }
                )
                .catch((error) => {
                    if (!options.disableNotifications) {
                        dispatch('notify', {
                            type: 'error',
                            title: (
                                <Trans i18nKey="common:notifications.errorTitle" />
                            ),
                            info: opts?.errorMessage || (
                                <Trans i18nKey="common:notifications.generalError" />
                            )
                        })
                    }
                    throw error
                })
                .then((response) => {
                    const errors = response?.data?.errors
                    if (errors) {
                        if (errors.length > 0 && errors[0]?.extensions?.code) {
                            handleDgsError(
                                errors[0]?.extensions?.code,
                                dispatch
                            )
                        } else {
                            const error = new Error(
                                'An error occurred while fetching the data.'
                            )
                            error.info = errors
                            if (!options.disableNotifications) {
                                dispatch('notify', {
                                    type: 'error',
                                    title: (
                                        <Trans i18nKey="common:notifications.errorTitle" />
                                    ),
                                    info: opts?.errorMessage || (
                                        <Trans i18nKey="common:notifications.generalError" />
                                    )
                                })
                            }
                            onError && onError(response)
                        }
                    } else {
                        return response.data.data
                    }
                })
                .then((data) => {
                    if (!!data && !options.disableNotifications) {
                        !opts?.disableSuccessNotify &&
                            dispatch('notify', {
                                type: 'success',
                                title: (
                                    <Trans i18nKey="common:notifications.successTitle" />
                                ),
                                info: opts?.successMessage || (
                                    <Trans i18nKey="common:notifications.success" />
                                )
                            })
                    }
                    if (
                        Array.isArray(cache) &&
                        cache.some((subKey) => Array.isArray(subKey))
                    ) {
                        cache.map((subKey) => swrMutate(subKey))
                    } else {
                        swrMutate(cache)
                    }
                    opts?.callback && opts?.callback(data)
                    return data
                })
        },
        [activeToken, options?.disableNotifications]
    )

    return { mutate, refetch: swrMutate }
}

const defaultOptions = { disableNotifications: false, autoClose: 1000 }
export default useMutation
