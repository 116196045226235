import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'noval'

const getBackground = (type) => {
    switch (type) {
        case 'normal':
            return '#801E41'
        case 'success':
            return '#74bc7d'
        case 'warning':
            return '#801E41'
        default:
            return '#801E41'
    }
}

const LayoutNotifications = () => {
    const { dispatch } = useDispatch()
    const notifications = useSelector('notifications', [])
    useEffect(() => {
        const nSuccess = notifications
            .filter((n) => n.type === 'success')
            .slice(-2, -1)
        const nWarning = notifications
            .filter((n) => n.type === 'warning')
            .slice(-2, -1)
        const nError = notifications
            .filter((n) => n.type === 'error')
            .slice(-2, -1)
        const newNotifications = [...nSuccess, ...nWarning, ...nError]

        dispatch({
            notifications: notifications.filter(
                (n) => !newNotifications.includes(n)
            )
        })
    }, [notifications.length])

    return (
        <div
            style={{ pointerEvents: 'fill' }}
            className="fixed top-10 rtl:left-6 ltr:right-6 h-auto flex flex-col gap-3 z-[999]"
        >
            {notifications.map(({ title = '', info, type, virId = '' }) => {
                return (
                    <div
                        key={virId}
                        style={{
                            background: getBackground(type),
                            color: '#FFF'
                        }}
                        onClick={() =>
                            dispatch({
                                notifications: notifications.filter(
                                    (n) => n.virId !== virId
                                )
                            })
                        }
                        className="shadow-lg py-2 px-3 flex items-start gap-3 w-[475px]"
                    >
                        <div className="transform scale-125">
                            <img
                                style={{
                                    minWidth: '20px',
                                    minHeight: '20px',
                                    maxHeight: '20px'
                                }}
                                src={`/img/notify/${type}.png`}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="text-md">{title}</span>
                            {info ? (
                                <span className="text-md">{info}</span>
                            ) : null}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default LayoutNotifications
