import axios from 'axios'
import { print } from 'graphql'
import { getNamedQuery } from './useQuery'
import { SURVEY_SERVICE_URL, getEnv } from '@util'
import { authStorage } from '@util/noval-helper/helper'
import {
    gqlWithParams,
    gqlWithoutParams,
    gqlWithoutParamsAndBody
} from './useGql'

const http = async (name, { vars, body, custom, opts }) => {
    const authData = authStorage.get()
    const token = authData.access_token

    const headers = token
        ? {
              Authorization: `Bearer ${token}`
          }
        : {}

    const { query, variables } = (() => {
        if (vars) {
            return gqlWithParams({ name, vars, body, custom })
        } else if (body) {
            return gqlWithoutParams({ name, body })
        } else {
            return gqlWithoutParamsAndBody({ name })
        }
    })()

    const namedQuery = getNamedQuery(name, query)

    return axios
        .post(
            getEnv(SURVEY_SERVICE_URL),
            {
                query: print(namedQuery),
                variables
            },
            { headers }
        )
        .then((response) => {
            if (response.data.errors) {
                return response.data.errors?.[0]?.message
            } else {
                opts?.callback && opts?.callback(response.data.data)
                return response.data.data
            }
        })
}

export default http
