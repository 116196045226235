import useMutation from '@hooks/useMutation'
import React, { useEffect } from 'react'
import MenuResize from 'public/img/action-menus/menu.svg'
import MainLogo from 'public/img/logos/upsilon-logo-toolbar.svg'
import Link from 'next/link'
import { MenuToolBar } from './menu-toolbar'
import { Mark } from '@hooks/useGql'
import { useDispatch, useSelector } from 'noval'

const Toolbar = ({ forceClose }) => {
    const [sub, screen] = useSelector(['user.sub', 'screen'])
    const isTabletScreen = screen === 'tablet'
    const isPhoneScreen = screen === 'phone'
    const { mutate } = useMutation({ disableNotifications: true })
    const { dispatch } = useDispatch()
    const { toggleMenu } = useSelector('toggleMenu', false)

    const setToggleMenu = (action) => {
        dispatch(({ state, update }) => {
            const { defaultMenu, toggleMenu } = state.toggleMenu
            update(
                {
                    toggleMenu: action ? true : !toggleMenu,
                    defaultMenu: action ? toggleMenu : defaultMenu
                },
                'toggleMenu'
            )
        })
    }

    const toggleAction = () => {
        dispatch(({ state, update }) => {
            const toggleMenu = !state.toggleMenu.toggleMenu
            mutate('updateNavBarMode', {
                vars: {
                    navBarMode: toggleMenu,
                    keycloakUserId: Mark(sub)
                }
            })
            update({ defaultMenu: toggleMenu, toggleMenu }, 'toggleMenu')
        })
    }

    useEffect(() => {
        if (!isPhoneScreen) {
            setToggleMenu(isTabletScreen)
        }
    }, [isTabletScreen])

    useEffect(() => {
        if (!isPhoneScreen) {
            setToggleMenu(forceClose)
        }
    }, [forceClose])

    return (
        <div
            className={`h-full flex flex-col items-start py-3 
                        relative bg-white transition duration-200 ${
                            toggleMenu
                                ? 'lg:w-[120px] w-[85px]'
                                : 'w-[230px] 2xl:w-[260px]'
                        }`}
        >
            <div className="flex w-full flex-row justify-between gap-5 mb-6 px-9 relative">
                <Link href="/">
                    <a className="cursor-pointer 2xl:w-full 2xl:max-w-full max-w-[120px] m-auto">
                        {/* {toggleMenu ? ( */}
                        {/* <MinLogo className="w-[46px] mt-10 ms-2" /> */}
                        {/* ) : ( */}
                        <MainLogo className={'w-full mt-10'} />
                        {/* )} */}
                    </a>
                </Link>
                <button
                    onClick={toggleAction}
                    disabled={isTabletScreen}
                    className={`absolute top-0 ltr:right-3 rtl:left-3 md:hidden lg:block ${
                        isTabletScreen ? 'opacity-30' : ''
                    } cursor-pointer bg-transparent`}
                >
                    <MenuResize
                        className="w-5 h-5"
                        style={{ fill: toggleMenu ? '#801E41' : '#666666' }}
                    />
                </button>
            </div>
            <MenuToolBar />
        </div>
    )
}

export default Toolbar
