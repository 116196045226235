import Button from '@components/button'
import React from 'react'

const PrimaryButton = ({ children, icon = null, ...props }) => {
    return (
        <Button primary {...props}>
            {icon}
            {children}
        </Button>
    )
}

export default PrimaryButton
