const createState = {
    helper: {
        initLogin: true
    },
    screen: 'phone',
    authn: null,
    keycloakClient: null,
    toggleMenu: {
        toggleMenu: false,
        defaultMenu: false
    },
    fullWidth: false,
    selectedTemplate: {
        id: '',
        templateJson: {},
        templateDesign: {},
        templateLogo: ''
    },
    teamManagement: {
        filters: {
            status: '',
            rating: '',
            city: ''
        },
        searchField: ''
    },
    ordersFilteration: {
        filters: {
            endDate: '',
            startDate: ''
        },
        searchFilter: ''
    },
    userOrdersFilteration: {
        filters: {
            surveyStatus: undefined,
            endDate: '',
            startDate: ''
        },
        searchFilter: ''
    },
    userAnalysisFilteration: {
        filters: {
            endDate: '',
            startDate: ''
        },
        searchFilter: ''
    },
    auditResponses: {
        responses: []
    },
    operatorOrdersFilteration: {
        filters: {
            status: '',
            endDate: '',
            startDate: ''
        },
        searchFilter: ''
    },
    launch: {
        submitState: false,
        addonsState: false,
        valuesState: {}
    },
    activePage: {
        activePage: 0
    },
    visitSourceFilter: 'ALL',
    visitCollectorFilter: 0,
    audtingList: {
        list: [],
        newList: []
    },
    assignmentRemainings: {
        remainingPrice: 0,
        remainingQuantity: 0
    },
    importSurvey: {
        json: '',
        added: false,
        questions: [],
        pageIndex: 0
    },
    assignResarchers: {
        searchResarcherField: ''
    },
    contactMessage: false,
    activeResponse: 1,
    operationSearch: {
        keywordSearch: undefined,
        question: undefined
    },
    customerAuditSearch: {
        keywordSearch: undefined,
        question: undefined
    }
}

export default createState
